import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { format } from "date-fns";
import { pt } from "date-fns/locale";
import imgForm from "../assets/Form.svg";
import imgForm2 from "../assets/Form2.svg";
import imgForm3 from "../assets/Form3.svg";
import imgForm4 from "../assets/Form4.svg";
import "./CoinForm.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import imgCoin1 from "../assets/coins/valor1.png";
import imgCoin5 from "../assets/coins/valor5.png";
import imgCoin10 from "../assets/coins/valor10.png";
import imgCoin100 from "../assets/coins/valor100.png";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";

const emocionadoresPorUnidadeSecao = {
  Caeté: {
    Limpeza: ["Maria da Silva Santos", "João Pereira Lima"],
    Governança: ["Ana Souza Oliveira", "Pedro Ferreira Costa"],
    Recepção: [
      "Ana Maria da Silva",
      "João Carlos Santos",
      "Maria Fernanda Oliveira",
    ],
    Guest: [
      "Pedro Henrique Rodrigues",
      "Juliana Pereira Lima",
      "Lucas Santos Costa",
    ],
    Manutenção: [
      "Gabriela Alves Souza",
      "Rafael Martins Ribeiro",
      "Camila Gonçalves Ferreira",
    ],
    Cozinha: [
      "André Luiz Pereira",
      "Laura Beatriz Vieira",
      "Gustavo Oliveira Barbosa",
    ],
    Recreação: [
      "Carolina Mendes Gomes",
      "Tiago Ferreira Almeida",
      "Vitória Lima Rodrigues",
    ],
    TI: [
      "Caio dos Santos Silva",
      "Bianca da Cruz Alves",
      "Felipe Pereira da Costa",
    ],
  },
  Atibáia: {
    Limpeza: [
      "Isabella Silva Rocha",
      "Daniel Barbosa Guedes",
      "Larissa Rodrigues Cardoso",
    ],
    Governança: [
      "Matheus Martins Pereira",
      "Luana Gonçalves Ribeiro",
      "Rodrigo Almeida Santos",
    ],
    Recepção: [
      "Lívia Fernandes Soares",
      "Lucas José da Silva",
      "Bruna Oliveira Lima",
    ],
    Guest: [
      "Gustavo de Sousa Pereira",
      "Marcela Silva Rocha",
      "Henrique Gonçalves Ribeiro",
    ],
    Manutenção: [
      "Amanda dos Santos Costa",
      "Vinícius Barbosa Ferreira",
      "Larissa Alves Cardoso",
    ],
    Cozinha: [
      "Igor Rodrigues Gomes",
      "Manuela Costa Rocha",
      "João Victor Pereira",
    ],
    Recreação: [
      "Isabelly Martins Silva",
      "Carlos Eduardo da Cruz",
      "Júlia Oliveira Guedes",
    ],
    TI: [
      "Renan Alves Santos",
      "Maria Luiza Pereira Lima",
      "Guilherme Rodrigues Sousa",
    ],
  },
  Alexânia: {
    Limpeza: [
      "Mariana Costa Cardoso",
      "Victor Martins Ferreira",
      "Laura Pereira Ribeiro",
    ],
    Governança: [
      "Arthur Alves Lima",
      "Isadora Rocha Silva",
      "Lucas Oliveira Santos",
    ],
    Recepção: [
      "Ana Clara Barbosa Costa",
      "Ricardo Guedes Pereira",
      "Débora Ribeiro Almeida",
    ],
    Guest: [
      "Gabriel da Cruz Rocha",
      "Fernanda Lima Oliveira",
      "Eduardo Gomes Silva",
    ],
    Manutenção: [
      "Alice Fernandes Barbosa",
      "Bruno Santos Rocha",
      "Fernanda Silva Gomes",
      "Thiago Gonçalves Oliveira",
    ],
    Cozinha: [
      "Marina Cardoso Santos",
      "Mateus Ferreira Barbosa",
      "Bianca Pereira Costa",
    ],
    Recreação: [
      "Enzo Martins Guedes",
      "Clara da Silva Ribeiro",
      "Felipe Lima Alves",
    ],
    TI: [
      "Beatriz Santos Pereira",
      "Cauã Oliveira Costa",
      "Sophia Rodrigues Lima",
    ],
  },
  Alegro: {
    Limpeza: [
      "João Pedro Almeida Santos",
      "Yasmin Martins Ribeiro",
      "Marcos Barbosa Ferreira",
    ],
    Governança: [
      "Letícia Oliveira Guedes",
      "Vinícius da Silva Costa",
      "Natália Pereira Rocha",
    ],
    Recepção: [
      "Rafael Santos Cardoso",
      "Maria Clara Fernandes Lima",
      "Gustavo Alves Silva",
    ],
    Guest: [
      "Eduarda Lima Pereira",
      "Matheus Rodrigues Guedes",
      "Lívia Oliveira Rocha",
    ],
    Manutenção: [
      "Pedro Henrique Costa Santos",
      "Laura Martins Cardoso",
      "Lucas Guedes Silva",
    ],
    Cozinha: [
      "Mariana Lima Pereira",
      "Enzo Rodrigues Almeida",
      "Isabela Silva Ribeiro",
    ],
    Recreação: [
      "Felipe Barbosa Ferreira",
      "Gabriela Oliveira Costa",
      "Lucas Santos Cardoso",
    ],
    TI: [
      "Manuela Alves Lima",
      "João Gabriel Pereira Silva",
      "Maria Luiza Guedes Rocha",
    ],
  },
  "João Pessoa": {
    Limpeza: [
      "Rafael Oliveira Santos",
      "Vitória Martins Lima",
      "Caio Almeida Silva",
    ],
    Governança: [
      "Luana Rodrigues Ribeiro",
      "Pedro Henrique Guedes Costa",
      "Larissa Silva Cardoso",
    ],
    Recepção: [
      "Guilherme Lima Rocha",
      "Bianca Santos Pereira",
      "Gustavo Oliveira Silva",
    ],
    Guest: [
      "Maria Clara Pereira Ribeiro",
      "Lucas Alves Guedes",
      "Letícia Lima Silva",
    ],
    Manutenção: [
      "Pedro Henrique Cardoso Santos",
      "Gabriela Rodrigues Costa",
      "João Victor Guedes Rocha",
    ],
    Cozinha: [
      "Isabella da Silva Almeida",
      "Gustavo Oliveira Santos",
      "Larissa Alves Costa",
    ],
    Recreação: [
      "Matheus Martins Ribeiro",
      "Bianca Rocha Lima",
      "Guilherme Ferreira Guedes",
    ],
    TI: [
      "Rafael Barbosa Silva",
      "Laura Oliveira Pereira",
      "Lucas Gonçalves Cardoso",
    ],
  },
  Araxá: {
    Limpeza: [
      "Maria Fernanda Lima Ribeiro",
      "Caio Santos Costa",
      "Carolina Alves Silva",
    ],
    Governança: [
      "Thiago Pereira Guedes",
      "Manuela Ribeiro Lima",
      "João Carlos Guedes Santos",
    ],
    Recepção: [
      "Vitória Costa Silva",
      "André Luiz Ribeiro",
      "Fernanda Martins Lima",
    ],
    Guest: [
      "Daniel da Silva Pereira",
      "Isadora Lima Guedes",
      "Pedro Oliveira Costa",
    ],
    Manutenção: [
      "Júlia Alves Silva",
      "Lucas Rodrigues Ribeiro",
      "Juliana Lima Guedes",
    ],
    Cozinha: [
      "Luiz Felipe Costa Pereira",
      "Amanda Pereira Silva",
      "Bruno Alves Guedes",
    ],
    Recreação: [
      "Larissa Santos Ribeiro",
      "Lucas Pereira Lima",
      "Vitória Almeida Costa",
    ],
    TI: [
      "Rafael Guedes Silva",
      "Gabriela Lima Pereira",
      "Guilherme da Silva Ribeiro",
    ],
  },
  Holding: {
    Limpeza: [
      "Ana Beatriz Guedes Costa",
      "João Lucas Ribeiro Lima",
      "Maria Luiza Costa Guedes",
    ],
    Governança: [
      "Gustavo Oliveira Pereira",
      "Bianca Alves Silva",
      "Matheus da Cruz Ribeiro",
    ],
    Recepção: [
      "Isabella Pereira Lima",
      "Lucas Guedes Costa",
      "Letícia Lima Ribeiro",
    ],
    Guest: [
      "Pedro Henrique Alves Silva",
      "Mariana Costa Guedes",
      "André Luiz Ribeiro Lima",
    ],
    Manutenção: [
      "Manuela Guedes Silva",
      "Rafael Oliveira Costa",
      "Luana Lima Pereira",
    ],
    Cozinha: [
      "Gustavo Alves Guedes",
      "Laura da Silva Ribeiro",
      "João Victor Lima Costa",
    ],
    Recreação: [
      "Isabela Guedes Silva",
      "Gabriel Ribeiro Pereira",
      "Maria Clara Almeida Lima",
    ],
    TI: ["Lucas Lima Guedes", "Camila Pereira Ribeiro", "Matheus Alves Costa"],
  },
};
const DeleteCoinForm = (props) => {
  const [emocionadoresFiltrados, setEmocionadoresFiltrados] = useState([]);
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    unidade: props.unidade || "",
    secao: props.secao || "",
    emocionador: props.emocionador || "",
    coins: props.coins || "",
    mandamento: props.mandamento || "",
    descricaoEmocao: props.descricaoEmocao || "",
    date: props.date || new Date(),
  });
  const [submitted, setSubmitted] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [coinImage, setCoinImage] = useState("");
  const [selectedUnidade, setSelectedUnidade] = useState(props.unidade || "");
  const [selectedSecao, setSelectedSecao] = useState(props.secao || "");
  const [unidadePlaceholder, setUnidadePlaceholder] =
    useState("Escolha a unidade");
  const [secaoPlaceholder, setSecaoPlaceholder] = useState(
    "Escolha o Departamento"
  );
  const [isDescriptionHidden, setDescriptionHidden] = useState(false);
  const [isDescriptionFilled, setDescriptionFilled] = useState(false);
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [isEmocionadorValid, setIsEmocionadorValid] = useState(true);

  const unidades = [
    "Caeté",
    "Atibáia",
    "Alexânia",
    "Alegro",
    "João Pessoa",
    "Araxá",
    "Holding",
  ];
  const secoes = [
    "Limpeza",
    "Governança",
    "Recepção",
    "Guest",
    "Manutenção",
    "Cozinha",
    "Recreação",
    "TI",
  ];

  const mandamentos = [
    " 1 - RIR ",
    // - Quem ri mais vive mais. Torne nosso atendimento caloroso.",
    " 2 - CUMPRIMENTE SEMPRE ",
    // - Bom dia! Boa tarde! Boa noite! Sempre com o gesto Tauá.",
    " 3 - CONVERSE COM O HÓSPEDE ",
    // - 'Você está precisando de alguma coisa?', 'Está gostando?'. Torne o atendimento mais próximo para que ele se sinta em casa. Fale seu nome, pergunte o nome dele.",
    " 4 - O CLIENTE É O REI",
    // - Pare tudo que está fazendo. Dê atenção ao hóspede: resolva o problema, carregue a mala, acompanhe-o até onde ele quer ir, pegue o que ele precisa.",
    " 5 - SEJA COLABORATIVO ",
    // - Ajude o seu colega e outros setores também. Pegue o papel do chão: todos somos responsáveis pela limpeza.",
    " 6 - ATENÇÃO TOTAL, CARA ALEGRE E RÁPIDO ",
    // - Busque excelência para os nossos serviços e tenha atenção aos detalhes. Trabalhe com dedicação, não fique no celular na hora do trabalho.",
    " 7 - TAUA E UMA EMPRESA DE OPORTUNIDADES ",
    // - Capacite-se e se esforce, pois queremos e incentivamos o crescimento interno. Esteja preparado para as oportunidades.",
    " 8 - CANTE A MÚSICA 'AMIGOS PARA SEMPRE' ",
    // - Cante alto, com entusiasmo e com um sorriso no rosto.",
    " 9 - TRABALHE EM PROL DO RESULTADO ",
    // - Acompanhe de perto suas metas. Seja ético e honesto em suas atitudes e tarefas, tanto para comprar, quanto para vender em nome do Tauá. Não desperdice.",
    " 10 - SEJA FELIZ ",
    // - Quando a gente faz alguém feliz, a gente também fica feliz. Tenha humildade, simplicidade, respeito, gratidão.",
  ];

  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.name === "coins") {
      setCoinImage(e.target.value);
      setForm({ ...form, coins: e.target.value });
    }
    if (e.target.name === "unidade") {
      setSelectedUnidade(e.target.value);
      setEmocionadoresFiltrados([]);
      setUnidadePlaceholder(e.target.value);
    }

    if (e.target.name === "secao") {
      setSelectedSecao(e.target.value);
      setEmocionadoresFiltrados([]);
      setSecaoPlaceholder(e.target.value);
    }

    if (e.target.name === "descricaoEmocao") {
      setForm({ ...form, [e.target.name]: e.target.value });
      setDescriptionFilled(!!e.target.value);
    } else {
      const emocionador = e.target.value;
      const unidade = selectedUnidade;
      const secao = selectedSecao;
      const isEmocionadorInList =
        emocionadoresPorUnidadeSecao[unidade]?.[secao]?.includes(emocionador) ||
        false;
      setIsEmocionadorValid(isEmocionadorInList);
      setForm({ ...form, [e.target.name]: e.target.value });
    }
    if (e.target.name === "emocionador") {
      const emocionador = e.target.value;
      const unidade = selectedUnidade;
      const secao = selectedSecao;
      const isEmocionadorInList =
        emocionadoresPorUnidadeSecao[unidade]?.[secao]?.includes(emocionador) ||
        false;
      setIsEmocionadorValid(isEmocionadorInList);
      setForm({ ...form, [e.target.name]: emocionador });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submission triggered");
    setSubmitted(true);

    if (clickCount === 0) {
      setClickCount(clickCount + 1);
    } else {
      if (step === 1) {
        // Verifique se o emocionador está na lista
        if (
          emocionadoresFiltrados.includes(form.emocionador) ||
          Object.values(emocionadoresPorUnidadeSecao).some((secoes) =>
            Object.values(secoes).some((emocionadores) =>
              emocionadores.includes(form.emocionador)
            )
          )
        ) {
          handleNextStep();
        } else {
          // Emocionador não encontrado, exiba uma mensagem de erro
          alert("Emocionador não encontrado na lista!");
        }
      } else if (step === 2) {
        handleNextStep();
      } else if (step === 3) {
        handleNextStep();
      } else if (step === 4) {

        console.log("Valor do contexto no Form:", form);
        navigate("/");
      }
    }
  };

  const handleNextStep = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    }
  };
  const handlePrevStep = () => {
    if (step === 2) {
      setSelectedUnidade("");
      setSelectedSecao("");
    }
    setStep(step - 1);
  };
  const handleDescriptionFocus = () => {
    setDescriptionHidden(true);
  };

  const handleDescriptionBlur = () => {
    setDescriptionHidden(false);
  };
  const handleTextareaChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setDescriptionFilled(!!e.target.value);
  };

  const handleDateSelect = (date) => {
    setDatePickerFocused(false);
    setForm({ ...form, date });
  };

  registerLocale("pt-BR", ptBR);
  useEffect(() => {
    if (selectedUnidade && selectedSecao) {
      const filteredEmocionadores =
        emocionadoresPorUnidadeSecao[selectedUnidade]?.[selectedSecao] || [];
      setEmocionadoresFiltrados(filteredEmocionadores);
    }
  }, [selectedUnidade, selectedSecao]);
  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="form-box">
        {step === 1 && (
          <div className="form-item">
            <img className="img-top" src={imgForm} alt="" />
            <div className="itens-form">
              <h2 className="container-title ">Emocionador</h2>
              <div className="form">
                <label className="label-form">
                  Unidade:
                  <div className="label-container">
                    <select
                      className="campos"
                      name="unidade"
                      onChange={handleChange}
                      value={selectedUnidade}
                      required
                    >
                      <option value="" disabled hidden>
                        {unidadePlaceholder}{" "}
                      </option>
                      {unidades.map((unidade) => (
                        <option key={unidade} value={unidade}>
                          {unidade}
                        </option>
                      ))}{" "}
                    </select>
                    <FontAwesomeIcon icon={faChevronDown} className="chevron" />
                  </div>
                </label>
                <label className="label-form">
                  Departamento:
                  <div className="label-container">
                    <select
                      className="campos"
                      name="secao"
                      onChange={handleChange}
                      value={selectedSecao}
                      required
                    >
                      <option value="" disabled hidden>
                        {secaoPlaceholder}
                      </option>
                      {secoes.map((secao) => (
                        <option key={secao} value={secao}>
                          {secao}
                        </option>
                      ))}
                    </select>
                    <FontAwesomeIcon icon={faChevronDown} className="chevron" />
                  </div>
                </label>

                <div className="form">
                  <label className="label-form">
                    Emocionador:
                    <div className="label-container">
                      <select
                        className={`campo-nome ${
                          isEmocionadorValid ? "" : "invalid"
                        }`}
                        name="emocionador"
                        required
                        value={form.emocionador}
                        onChange={handleChange}
                      >
                        <option value="" disabled hidden>
                          Escolha o emocionador
                        </option>
                        {emocionadoresPorUnidadeSecao[selectedUnidade]?.[
                          selectedSecao
                        ]?.map((emocionador) => (
                          <option key={emocionador} value={emocionador}>
                            {emocionador}
                          </option>
                        ))}
                      </select>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="chevron"
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="result">
                {submitted && (
                  <div className="container-respostas ">
                    <div className="respostas">
                      <p className="font">Emocionador: {form.emocionador}</p>
                      <p className="font">Unidade: {form.unidade}</p>
                      <p className="font">Departamento: {form.secao}</p>
                    </div>
                  </div>
                )}
                <input
                  className="form-butom1"
                  type="submit"
                  value={clickCount === 1 ? "Avançar" : "Confirmar"}
                />
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="container-itens">
            <img className="img-top" src={imgForm2} alt="" />
            <div className="itens-form">
              <h2 className="container-title ">Emocionador</h2>
              <div className="info-box">
                <div className="info">
                  <p className="font">Emocionador: {form.emocionador}</p>
                  <p className="font">Unidade: {form.unidade}</p>
                  <p className="font">Departamento: {form.secao}</p>
                </div>
              </div>

              <div className="coins">
                <div className="coin-container">
                  <label className="coin-label">
                    <input
                      type="radio"
                      name="coins"
                      value="1"
                      checked={form.coins === "1"}
                      onChange={handleChange}
                      required
                      style={{ display: "none" }}
                    />
                    <img
                      src={imgCoin1}
                      alt="1 Coin"
                      className={form.coins === "1" ? "selected-coin" : "coin"}
                      onClick={() => {
                        setForm({ ...form, coins: "1" });
                        setCoinImage(imgCoin1);
                      }}
                      style={{ width: "150px", height: "150px" }} // Aumente o tamanho aqui
                    />
                  </label>
                  <label className="coin-label">
                    <input
                      type="radio"
                      name="coins"
                      value="5"
                      checked={form.coins === "5"}
                      onChange={handleChange}
                      required
                      style={{ display: "none" }}
                    />
                    <img
                      src={imgCoin5}
                      alt="5 Coins"
                      className={form.coins === "5" ? "selected-coin" : "coin"}
                      onClick={() => {
                        setForm({ ...form, coins: "5" });
                        setCoinImage(imgCoin5);
                      }}
                      style={{ width: "150px", height: "150px" }} // Aumente o tamanho aqui
                    />
                  </label>
                  <label className="coin-label">
                    <input
                      type="radio"
                      name="coins"
                      value="10"
                      checked={form.coins === "10"}
                      onChange={handleChange}
                      required
                      style={{ display: "none" }}
                    />
                    <img
                      src={imgCoin10}
                      alt="10 Coins"
                      className={form.coins === "10" ? "selected-coin" : "coin"}
                      onClick={() => {
                        setForm({ ...form, coins: "10" });
                        setCoinImage(imgCoin10);
                      }}
                      style={{ width: "150px", height: "150px" }} // Aumente o tamanho aqui
                    />
                  </label>
                  <label className="coin-label">
                    <input
                      type="radio"
                      name="coins"
                      value="100"
                      checked={form.coins === "100"}
                      onChange={handleChange}
                      required
                      style={{ display: "none" }}
                    />
                    <img
                      src={imgCoin100}
                      alt="100 Coins"
                      className={
                        form.coins === "100" ? "selected-coin" : "coin"
                      }
                      onClick={() => {
                        setForm({ ...form, coins: "100" });
                        setCoinImage(imgCoin100);
                      }}
                      style={{ width: "150px", height: "150px" }} // Aumente o tamanho aqui
                    />
                  </label>
                </div>
              </div>
              <div className="coin-selection-message">
                {form.coins && <p>Moeda selecionada: ${form.coins}</p>}
              </div>
              <div className="button-nextprevew ">
                <input
                  className="form-butomVoltar"
                  type="submit"
                  value="Voltar"
                  onClick={handlePrevStep}
                />
                <input
                  className="form-butom"
                  type="submit"
                  value="Avançar"
                  onClick={handleNextStep}
                  disabled={!form.coins}
                />
              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="container-itens">
            <img className="img-top" src={imgForm3} alt="" />
            <div className="itens-form">
              <h2 className="container-title ">Emocionador</h2>
              <div className="info-command">
                <div className="info">
                  <p style={{ fontSize: "20px" }}>
                    Emocionador: {form.emocionador}
                  </p>
                  <p style={{ fontSize: "20px" }}>Unidade: {form.unidade}</p>
                  <p style={{ fontSize: "20px" }}>Departamento: {form.secao}</p>
                </div>
              </div>
              <div className="label-command">
                <label className="label-form">
                  <div className="label-container">
                    <select
                      className="campo-mandamentos"
                      name="mandamento"
                      onChange={handleChange}
                      value={form.mandamento}
                      required
                    >
                      <option value="" disabled hidden>
                        Selecione o mandamento
                      </option>
                      {mandamentos.map((mandamento) => (
                        <option key={mandamento} value={mandamento}>
                          {mandamento}
                        </option>
                      ))}
                    </select>
                    <FontAwesomeIcon icon={faChevronDown} className="chevron" />
                  </div>
                </label>
                <div>
                  <label className="input-command">
                    <textarea
                      className="input-text"
                      name="descricaoEmocao"
                      onChange={handleTextareaChange} // Usar o novo manipulador de eventos
                      onFocus={handleDescriptionFocus}
                      onBlur={handleDescriptionBlur}
                      required
                    ></textarea>
                    {!isDescriptionFilled && (
                      <span
                        className={
                          "description-span " +
                          (isDescriptionHidden ? "hidden" : "")
                        }
                      >
                        Descrição da emoção: obrigatório*
                      </span>
                    )}
                  </label>
                </div>
                <div className="command-data">
                  <label className="form-date-info">
                    Emocionou na
                    <DatePicker
                      className="form-date"
                      selected={form.date}
                      placeholderText="Selecione a data"
                      onChange={handleDateSelect}
                      onFocus={() => setDatePickerFocused(true)}
                      onBlur={() => setDatePickerFocused(false)}
                      open={datePickerFocused}
                      locale="pt-BR"
                      dateFormat="EEE d 'de' MMM"
                      required
                    />
                  </label>
                </div>
              </div>
              <div className="button-nextprevew ">
                <input
                  className="form-butomVoltar"
                  type="submit"
                  value="Voltar"
                  onClick={handlePrevStep}
                />
                <input className="form-butom" type="submit" value="Avançar" />
              </div>
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="container-itens">
            <img className="img-top" src={imgForm4} alt="" />
            <h2 className="container-title ">Emocionador</h2>
            <div className="box-final">
              <div className="info-final">
                <div className="colum1">
                  <p style={{ fontSize: "20px", textAlign: "justify" }}>
                    Emocionador: {form.emocionador}
                  </p>
                  <p style={{ fontSize: "20px", textAlign: "justify" }}>
                    Valor: $ {form.coins}
                  </p>
                  {coinImage && (
                    <img
                      src={coinImage}
                      alt={`Coin ${form.coins}`}
                      style={{ width: "150px", height: "150px" }}
                    />
                  )}
                </div>
                <div className="colum2">
                  <p className="final-respostas">Unidade: {form.unidade}</p>
                  <p className="final-respostas">Departamento: {form.secao}</p>
                  <p className="final-respostas">
                    Mandamento: {form.mandamento}
                  </p>
                  <p className="final-respostas">
                    Descrição da emoção: {form.descricaoEmocao}
                  </p>
                  <p className="final-respostas">
                    Emocionou no dia:{" "}
                    {format(form.date, "dd 'de' MMMM 'de' yyyy", {
                      locale: pt,
                    })}
                  </p>
                </div>
              </div>
            </div>
            <div className="button-nextprevew ">
              <input
                className="form-butomVoltar"
                type="submit"
                value="Voltar"
                onClick={handlePrevStep}
              />
              <input
                className="form-butom"
                type="submit"
                value="Avançar"
                onClick={handleNextStep}
              />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default DeleteCoinForm;
