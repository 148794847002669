import './App.css';

import Login from './components/Login';
import Layout from './components/Layout';
import Home from './components/Home';
import Manager from './components/Manager';
import Admin from './components/Admin';
import NotFound from './components/NotFound';
import LinkPage from './components/LinkPage';
import DeleteCoinForm from './components/DeleteCoinForm';
import CoinForm from './components/CoinForm';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './components/Unauthorized'

import { Routes, Route } from 'react-router-dom';

function App() {
	return (
		<Routes>
			<Route path='/' element={<Layout />}>
			{/** Public Routes */}
			<Route path='link-page' element={<LinkPage/>} />
			<Route path='login' element={<Login />} />

			{/** Protect routes */}
			<Route element={<RequireAuth allowedRoles={['MEMBER', 'ADMIN', 'MANAGER']} />}>
				<Route path='/' element={<Home/>} />
				<Route path='nao-autorizado' element={<Unauthorized />} />
			</Route>

			<Route element={<RequireAuth allowedRoles={['ADMIN', 'MANAGER']} />}>
				<Route path='excluir-moeda' element={<DeleteCoinForm/>} />
			</Route>

			<Route element={<RequireAuth allowedRoles={['ADMIN', 'MANAGER']} />}>
				<Route path='atribuir-moeda' element={<CoinForm/>} />
			</Route>

			<Route element={<RequireAuth allowedRoles={['MANAGER']} />}>
				<Route path='gestor' element={<Manager/>}/>
			</Route>

			<Route element={<RequireAuth allowedRoles={['ADMIN']} />}>
				<Route path='admin' element={<Admin/>}/>
			</Route>

			{/** Catch all - 404 */}
			<Route path='*' element={<NotFound/>}/>
			</Route>
		</Routes>
	);
}

export default App;
