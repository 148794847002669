import React, { useState } from "react";
import "./Navbar.css";

import { Link } from 'react-router-dom'

import logo from "../../assets/banco_felicidade.png";

const Navbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <nav className="navbar">
           
            <Link className="navbar-text" to="/">
                <span>
                    <img className="" src={logo} alt="Logo" />
                </span>
                Banco da Felicidade
            </Link>
            <button className="nav-button" onClick={toggleNav}>☰</button>
            <div className={`navbar-menu ${isNavOpen ? "menu-open" : ""}`}>
                <Link to="/" className="loja-link">Inicio</Link>
                <Link to="/admin" className="login-link">Admin</Link>
                <Link to="/gestor" className="login-link">Gestor</Link>
                <Link to="/" className="login-link">Sair</Link>
            </div>
        </nav>
    );
};

export default Navbar;
